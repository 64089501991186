.company-introduction {
    max-width: max-content;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .intro-section {
    margin-bottom: 30px;
  }
  
  .intro-section h2 {
    color: #333;
    margin-bottom: 10px;
  }
  
  .intro-section p {
    color: #555;
    line-height: 1.6;
  }
  
  .intro-section ul {
    padding-left: 20px;
    list-style-type: disc;
  }
  
  .intro-section li {
    color: #555;
    margin-bottom: 5px;
  }
  
  .intro-image {
    width: 30%;
    height: auto;
    margin-bottom: 15px;
    border-radius: 8px;
  }

.intro-image {
  width: 50%;
  height: auto;
  margin-bottom: 15px;
  border-radius: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add transition for smooth effect */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Initial shadow */
}

.intro-image:hover {
  transform: scale(1.05); /* Scale the image slightly on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Increase shadow on hover */
}
