.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2C3E50;
  color: white;
  padding: 20px;
}

.footer .footer-links,
.footer .social-media {
  display: flex;
  gap: 20px;
}

.footer .footer-links a,
.footer .social-media a {
  color: white;
  text-decoration: none;
}

.footer .newsletter-signup form {
  display: flex;
  gap: 10px;
  align-items: center;
}

.footer .newsletter-signup input {
  padding: 10px;
  border: none;
  border-radius: 5px;
}

.footer .newsletter-signup button {
  padding: 10px;
  border: none;
  background-color: #E74C3C;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.footer .newsletter-signup button:hover {
  background-color: #C0392B;
}
