.hero-section {
  position: relative;
  overflow: hidden; /* Prevent content overflow */
  color: white; /* Ensure text is readable */
  height: 50vh;
  padding: 80px 20px; /* Adjust padding to suit design */
  text-align: center; /* Center the text */
}

.hero-section video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the video covers the entire section */
  z-index: -1; /* Place video behind other content */
  display: block; /* Ensure video is displayed */
}

.hero-section video[style~='none'] {
  display: none;
}

.hero-section video[style~='block'] {
  display: block;
}

.hero-section h1 {
  font-size: 3em;
  animation: fadeInUp 5s ease-in-out infinite;
}

.hero-section p {
  font-size: 1.5em;
  animation: scaleIn 5s ease-in-out infinite 1s;
}
.hero-section button {
  padding: 10px 20px;
  border: none;
  background-color: #e74c3c;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  animation: fadeInUp 2s ease-in-out 1s;
}

.home-page {
  padding: 20px;
}

.introduction, .key-highlights {
  padding: 20px;
  text-align: left;
  background-color: rgba(255, 255, 255, 0.82); /* Add a semi-transparent white background to content for readability */
}

.key-highlights .highlight {
  background-color: #ecf0f1;
  padding: 20px;
  margin-top: 10px;
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes scaleIn {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
