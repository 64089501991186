.contact-us {
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.contact-us h2 {
  text-align: center;
  margin-bottom: 20px;
}

.contact-us .form-group {
  margin-bottom: 15px;
}

.contact-us .form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.contact-us .form-group input,
.contact-us .form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.contact-us .form-group .error {
  color: red;
  font-size: 0.9em;
  margin-top: 5px;
  display: block;
}

.contact-us button {
  padding: 10px 20px;
  border: none;
  background-color: #e74c3c;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  width: 100%;
  font-size: 1em;
  margin-top: 20px;
}

.contact-us button:hover {
  background-color: #c0392b;
}
