.our-services {
    padding: 20px;
    font-family: Arial, sans-serif;
}

.service {
    margin-bottom: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.service h2 {
    margin-bottom: 10px;
}

.service button {
    background-color: #004080;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
}

.service button:hover {
    background-color: #003060;
}

.vc-details {
    margin-top: 15px;
    padding: 15px;
    background-color: #e6f7ff;
    border-left: 5px solid #004080;
    border-radius: 5px;
}

.vc-details h3 {
    margin-bottom: 10px;
}

.vc-details ol {
    padding-left: 20px;
}

.vc-details ol li {
    margin-bottom: 10px;
}
.it-details {
    margin-top: 15px;
    padding: 15px;
    background-color: #e6f7ff;
    border-left: 5px solid #004080;
    border-radius: 5px;
}

.it-details h3 {
    margin-bottom: 10px;
}

.it-details ol {
    padding-left: 20px;
}

.it-details ol li {
    margin-bottom: 10px;
}

.consulting-details {
    margin-top: 15px;
    padding: 15px;
    background-color: #e6f7ff;
    border-left: 5px solid #004080;
    border-radius: 5px;
}

.consulting-details h3 {
    margin-bottom: 10px;
}

.consulting-details ol {
    padding-left: 20px;
}

.consulting-details ol li {
    margin-bottom: 10px;
}

