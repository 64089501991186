.about-us {
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
  background-color: #ECF0F1;
}

/* Shared styles for sections */
.who-we-are,
.mission-vision {
  background-color: #ffffff;
  padding: 20px;
  margin-top: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.who-we-are h2,
.mission-vision h2 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #2C3E50;
}

/* Who We Are Content Layout */
.who-we-are-content {
  display: flex;
  align-items: center;
  gap: 20px;
}

.who-we-are-image {
  max-width: 10%; /* Set the image size to 10% */
  height: auto;   /* Keep the height proportional */
  border-radius: 8px;
  object-fit: cover; /* Ensure the image maintains a good aspect ratio */
}

.who-we-are-text {
  flex: 1;
  text-align: left; /* Align text to the left */
}

.who-we-are-text p {
  font-size: 1.1em;
  line-height: 1.8;
  color: #555;
}

/* Mission and Vision content */
.mission-vision .content {
  display: flex;
  gap: 20px;
  text-align: left; /* Align text to the left */
}

.mission-vision .content > div {
  flex: 1;
  padding: 15px;
  background-color: #F9FAFB;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  text-align: left; /* Align text inside the div to the left */
}

.mission-vision h3 {
  font-size: 1.6em;
  margin-bottom: 10px;
  color: #34495E;
}

.mission-vision ul {
  margin-top: 10px;
  margin-left: 20px;
  font-size: 1em;
  line-height: 1.6;
}

.mission-vision ul li {
  margin-bottom: 8px;
}

.mission-vision p {
  margin-top: 10px;
  font-size: 1em;
  line-height: 1.6;
  text-align: left; /* Align paragraph text to the left */
}

/* Highlighted text */
strong {
  color: #0056B3;
}

/* Media Query for Responsiveness */
@media (max-width: 768px) {
  .who-we-are-content {
    flex-direction: column;
    text-align: center;
  }

  .who-we-are-image {
    width: 80%;
    margin-bottom: 20px;
  }

  .who-we-are-text {
    width: 100%;
    text-align: left; /* Align text left on smaller screens */
  }

  .mission-vision .content {
    flex-direction: column;
  }
}
