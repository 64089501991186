body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden; /* Prevent horizontal overflow */
}

.App {
  text-align: center;
  position: relative; /* Ensure positioning context for video overlay */
  overflow: hidden; /* Prevent content overflow */
}

video.bg-video {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1; /* Place video behind other content */
  background-size: cover;
}

.content {
  padding-top: 140px; /* Adjust based on header height */
  background-color: none /* Add a semi-transparent white background to content for readability */
}
