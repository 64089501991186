.header {
  position: fixed; /* Make the header fixed */
  top: 0; /* Position it at the top of the page */
  width: 100%; /* Make it span the full width of the page */
  background-color: #34495e; /* Set a solid background color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for better visibility */
  z-index: 1000; /* Ensure it stays above other content */
  display: flex;
  align-items: center; /* Center vertically */
  padding: 20px 40px; /* Increase padding to make header larger */
  height: 120px; /* Set a specific height to double the original */
}

.header .logo {
  margin-right: 40px; /* Add margin to separate logo and nav */
}

.header .logo img {
  height: 80px; /* Make the logo proportionally larger */
}

.header nav {
  flex: 1; /* Take remaining space for centering */
}

.header nav ul {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0; /* Reset default margin */
  padding: 0; /* Reset default padding */
}

.header nav ul li a {
  color: #ecf0f1; /* Change text color for better contrast */
  font-weight: bold;
  text-decoration: none; /* Remove underline */
  position: relative; /* For positioning the underline */
  font-size: 1.2em; /* Increase font size */
}

.header nav ul li a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  display: block;
  margin-top: 5px;
  right: 0;
  background: blue;
  transition: width 0.4s ease;
  -webkit-transition: width 0.4s ease;
}

.header nav ul li a:hover::after {
  width: 100%;
  left: 0;
  background: blue;
}

/* Ensure the rest of the content does not hide behind the fixed header */
.content {
  padding-top: 140px; /* Adjust based on header height */
}
