.chatbot-container {
    position: fixed;
    bottom: 70px;
    right: 20px;
    width: 400px; /* Set a fixed width for the chat window */
    height: 500px; /* Set a fixed height for the chat window */
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    z-index: 1000;
    cursor: move;
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.82); /* Add a semi-transparent white background to content for readability */

  }
  
  .chatbot-messages {
    flex: 1; /* Allow the messages container to expand */
    overflow-y: auto; /* Enable vertical scrolling */
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .chatbot-message {
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 4px;
    max-width: 80%;
    white-space: pre-wrap; /* Preserve whitespace for typing effect */
    text-align: left; /* Ensure text is aligned to the left */
    align-self: flex-start; /* Align messages to the start */
    
  }
  
  .chatbot-message.user {
    background-color: #007bff;
    color: white;
    align-self: flex-end; /* User messages align right */
  }
  
  .chatbot-message.assistant {
    background-color: #f1f1f1;
    color: #333;
  }
  
  .chatbot-input-container {
    display: flex;
    border-top: 1px solid #ccc;
    padding: 10px;
  }
  
  .chatbot-input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    outline: none;
  }
  
  .chatbot-send-button {
    margin-left: 10px;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }
  
  .chatbot-send-button:hover {
    background-color: #0056b3;
  }
  
  .typing {
    font-style: italic;
    color: #888;
  }
  .chatbot-toggle-button {
    position: fixed;
    bottom: 20px; /* Adjust bottom position */
    right: 20px; /* Adjust right position */
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    z-index: 1001; /* Ensure the button is above other elements */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .chatbot-toggle-button:hover {
    background-color: #0056b3;
  }
  