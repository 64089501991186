.news-and-insights {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.news-and-insights h2 {
  text-align: center;
  margin-bottom: 20px;
}

.news-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.news-item {
  display: flex;
  align-items: center;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.news-image {
  width: 100px;
  height: 100px;
  margin-right: 20px;
  border-radius: 5px;
  object-fit: cover;
}

.news-content {
  flex: 1;
}

.news-item h3 {
  margin-top: 0;
}

.news-item a {
  color: #007bff;
  text-decoration: none;
}

.news-item a:hover {
  text-decoration: underline;
}
